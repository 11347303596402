<script>

export default {
    data() {
        return {
            magician: null,
        }
    },
    methods: {
        loader(to) {
            console.log(to);
            this.magician = to;
        },
    },
    created() {
        window.location.href = window.location.origin + "/game/index.html";
    },
}
</script>
<template>
     <div class="row h-100 justify-content-center align-items-center b-load">
        <div class="col-12 ">
            <i class="fa-solid fa-circle-notch fa-spin fa-5x text-danger"></i>
            <h3 class="mt-4 text-danger">Cargando</h3>
        </div>
    </div>
</template>
<style>
iframe {
    display: block;
    /* iframes are inline by default */
    height: 96vh;
    width: 100%;
    border: 0px;
}
</style>