<template>
  <div id="app">
    <vue-snotify />
    <div id="full-page">
      <div class="layout max-w-lg mx-auto bg-pttr-white">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  computed: {
    alert() {
      return this.$store.state.alert
    },
    load() {
      return this.$store.state.status.routeChange;
      // return this.$store.state.routeChange
    }
  },
  watch: {
    'alert.type'() {
      // let vm = this;
      if (this.alert.type) {
        if (this.alert.type == 'success') {
          this.$snotify.success(this.alert.message);
        }
        if (this.alert.type == 'error') {
          this.$snotify.error(this.alert.message);
        }
        setTimeout(() => {
          this.clearNotification()
        }, 2000);
      }
    },
    'load'() {
      console.log('cambio');
    }
  },
  methods: {
    ...mapActions({
      clearNotification: 'alert/clear'
    })
  }
}
</script>

<style>
.bg-pttr-white {
  background-color: #ddd;
  background-size: 800px auto;
}

body {
  background-image: url('@/assets/8.jpg');
  background-size: 800px;
  font-family: "Muller Regular", sans-serif;
  touch-action: none;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.max-w-lg {
  max-width: 32rem;
}

.layout {
  overflow: hidden;
  height: 96vh;
  margin: auto;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(73, 67, 102, 0.5);
  border-radius: 20px;
  border: 2px solid #ddd;
}

#app {
  margin-top: 2.5vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#full-page {
  height: 100%;
  width: 100%;
}

.unity-desktop{
  width: 100% !important;
  height: 100% !important;
}
</style>
