<script>
import { mapActions } from 'vuex';
import decrypt from "@/services/decrypt";
export default {
    data() {
        return {
            lat: '',
            long: '',
        }
    },
    methods: {
        getPositionManual() {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        this.lat = latitude;
                        this.long = longitude;
                        this.checkQueryToken();

                        // Aquí puedes hacer lo que necesites con la ubicación obtenida
                    },
                    (error) => {
                        this.checkQueryToken();
                        switch (error.code) {

                            case error.PERMISSION_DENIED:
                                console.error('El usuario denegó la solicitud de ubicación.');
                                // Aquí puedes mostrar un mensaje al usuario sobre la necesidad de permitir el acceso a la ubicación.
                                break;
                            case error.POSITION_UNAVAILABLE:
                                console.error('Información de ubicación no disponible.');
                                break;
                            case error.TIMEOUT:
                                console.error('Tiempo de espera agotado para obtener la ubicación.');
                                break;
                            case error.UNKNOWN_ERROR:
                                console.error('Error desconocido al obtener la ubicación.');
                                break;
                            default:
                                break;
                        }
                    }
                );
            } else {
                console.error('Geolocalización no es compatible en este navegador.');
                // Aquí puedes mostrar un mensaje indicando que la geolocalización no es compatible con el navegador.
            }
        },

        async requestGeolocationPermission() {
            return new Promise((resolve, reject) => {
                navigator.permissions
                    .query({ name: 'geolocation' })
                    .then(permissionStatus => {
                        resolve(permissionStatus.state);
                        permissionStatus.onchange = () => {
                            resolve(permissionStatus.state);
                        };
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        async getCurrentPosition() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
        },
        async checkQueryToken() {


            let vm = this;
            if (vm.$route.query && Object.keys(vm.$route.query).length > 0) {
                let _params = {};
                Object.keys(vm.$route.query).forEach((key) => {
                    try {
                        console.log(key + ": ", vm.$route.query[key]);
                        localStorage.setItem(`cs_${key}`, vm.$route.query[key])
                        _params[key] = decrypt.AESDecrypt(vm.$route.query[key]);
                        console.log(key + ": ", _params[key]);
                    } catch (error) {
                        console.log("error: ", error);
                    }
                });

                if (_params && Object.keys(_params).length > 0) {
                    _params.lat = this.lat;
                    _params.long = this.long;
                    await vm
                        .authLoginWithToken(_params)
                        .then((data_login) => {
                            console.log(data_login);
                            location.reload();
                            // vm.$snotify.success("¡Bienvenido!");
                            // vm.$router.push({ name: "app-home" });
                        })
                        .catch((err) => {
                            try {
                                // vm.$snotify.error(err.response.data.message);
                                console.log(err.response.data.message);
                            } catch (err) {
                                console.log("err", err);
                                // vm.$snotify.error("Ups. Parece que algo salio mal...");
                            }
                        });
                }
            } else {
                await this.errorNotificationMsg('Es requerido el código para iniciar sesión')
            }
        },
        ...mapActions({
            loginAction: 'auth/login',
            errorNotification: 'alert/error',
            errorNotificationMsg: 'alert/errorMessage',
            authLoginWithToken: "auth/loginWithToken",
        })
    },
    async mounted() {
        this.checkQueryToken();
    }
}

</script>
<template>
    <div class="row h-100 justify-content-center align-items-center b-load">
        <div class="col-12 ">
            <i class="fa-solid fa-circle-notch fa-spin fa-5x text-danger"></i>
            <h3 class="mt-4 text-danger">Cargando</h3>
        </div>
    </div>
</template>
<style>
.b-load {
    background-color: #d0d0d0;
}
</style>