import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'

import Game from '@/page/GamePage';
import Login from '@/page/LoginPage';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'login',
		component: Login,
	},
	{
		path: '/juego',
		name: 'juego',
		component: Game,
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	store.state.status.showMenu = false;
	// Cargador de pagina
	if (to.name != from.name) {
		store.state.status.routeChange = true;
	}

	// Verificamos si esta logueado
	// const publicPages = ['login'];
	// const authRequired = !publicPages.includes(to.name);

	// Verificamos si esta logueado
	const loggedIn = JSON.parse(localStorage.getItem('user'));
	console.log(loggedIn);

	// Redirige a home si se loguea
	if (loggedIn) {		
		if (to.name == 'login' || to.name == null) {		
			return next('juego');
		}
	}

	if (!loggedIn) {
		if(to.name == 'juego' || from.name == 'juego'){						
			return next('/');
		}
	}

	next();

});

router.afterEach((to, from) => {
	// Cargador de pagina
	if (to.name != from.name) {
		setTimeout(() => {
			store.state.status.routeChange = false;
		}, 1000);
	}

})


export default router
