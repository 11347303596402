import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { authHeader } from './helpers';
authHeader()

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 6000
  }
}
Vue.use(Snotify, options)



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
